import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Styled components for enhanced formatting
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

function DiversityAndInclusionPage() {
  const { t } = useTranslation(); // Hook to access translations

  return (
    <Container>
      <Heading>{t('DiversityPage.welcome.heading')}</Heading>
      <Section>
        <Paragraph>
          {t('DiversityPage.welcome.content')}
        </Paragraph>
      </Section>
      <Section>
        <SubHeading>{t('DiversityPage.commitment.heading')}</SubHeading>
        <Paragraph>
          {t('DiversityPage.commitment.content')}
        </Paragraph>
      </Section>
      <Section>
        <SubHeading>{t('DiversityPage.culture.heading')}</SubHeading>
        <List>
          <ListItem>
            <strong>{t('DiversityPage.culture.education.heading')}:</strong> {t('DiversityPage.culture.education.content')}
          </ListItem>
          <ListItem>
            <strong>{t('DiversityPage.culture.resources.heading')}:</strong> {t('DiversityPage.culture.resources.content')}
          </ListItem>
          <ListItem>
            <strong>{t('DiversityPage.culture.engagement.heading')}:</strong> {t('DiversityPage.culture.engagement.content')}
          </ListItem>
        </List>
      </Section>
      <Section>
        <SubHeading>{t('DiversityPage.action.heading')}</SubHeading>
        <Paragraph>
          {t('DiversityPage.action.content')}
        </Paragraph>
        <List>
          <ListItem>
            <strong>{t('DiversityPage.action.recruitment.heading')}:</strong> {t('DiversityPage.action.recruitment.content')}
          </ListItem>
          <ListItem>
            <strong>{t('DiversityPage.action.partnerships.heading')}:</strong> {t('DiversityPage.action.partnerships.content')}
          </ListItem>
          <ListItem>
            <strong>{t('DiversityPage.action.improvement.heading')}:</strong> {t('DiversityPage.action.improvement.content')}
          </ListItem>
        </List>
      </Section>
      <Section>
        <SubHeading>{t('DiversityPage.join.heading')}</SubHeading>
        <Paragraph>
          {t('DiversityPage.join.content')}
        </Paragraph>
      </Section>
    </Container>
  );
}

export default DiversityAndInclusionPage;
