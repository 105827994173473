import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {motion} from 'framer-motion';
import React, { FunctionComponent } from 'react';
import './about.scss';
import Collaborators from './collaborators/collaborators';
import DownPage from './down-page/down-page';
import Faq from './faq/faq';
import Mission from './mission/mission';
import Presentation from './presentation/presentation';
import Team from './team/team';

library.add(fab);

const AboutComponent: FunctionComponent = () => {
    return (
        <motion.div
        initial={{opacity: 0}}
        exit={{opacity: 0}}
        animate={{opacity: 1}}
        >
            <Presentation/>
            <br/>
            <Mission/>
            <br/>
            <Team/>
            <br/>
            <Collaborators/>
            <br/>
            <Faq />
            <br/>
            <DownPage/>
        </motion.div>
    );
};

export default AboutComponent;