import DATACENTER from '../images/server.jpeg';
import Project from '../interfaces/project';

import i18n from '../i18n';

const DEV_PROJECTS: Project[] = [
    {
        titleI18nKey: 'DevProject.dataCenter.title',
        descriptionI18nKey: 'DevProject.dataCenter.description',
        image: DATACENTER,
    },
];

export default DEV_PROJECTS;