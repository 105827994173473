import i18n from '../i18n';
import Member from '../interfaces/member';

const MEMBERS: Member[] = [
    {
        imageName: 'Haroun Mili.png',
        posteI18nKey: 'Member.HarounMili.role',
        name: 'Haroun Mili',
        texteI18nKey: 'Member.HarounMili.text',
        linkedin: 'https://www.linkedin.com/in/haroun-mili-74aba4154/',
    },
    // directors
    {
        imageName: 'Nada-Alami.png',
        posteI18nKey: 'Member.NadaAlami.role',
        name: 'Nada Alami',
        linkedin: 'https://www.linkedin.com/in/nada-alami/',
        texteI18nKey: 'Member.NadaAlami.text',
    },
    {
        imageName: 'Hossam-Moustafa.png',
        posteI18nKey: 'Member.HossamMoustafa.role',
        name: 'Hossam Moustafa',
        linkedin: 'https://www.linkedin.com/in/hossam-moustafa-/',
        texteI18nKey: 'Member.HossamMoustafa.text',
    },
       // // developpers' team
    {
        imageName: 'Casta-Ung.jpg',
        posteI18nKey: 'Member.CastaUng.role',
        name: 'Casta Ung',
        linkedin: 'https://www.linkedin.com/in/castaung/',
        texteI18nKey: 'Member.CastaUng.text',
    },
    {
        imageName: 'Bryan-Junior-Ngatshou.png',
        posteI18nKey: 'Member.BryanJuniorNgatshou.role',
        name: 'Bryan Jr. Ngatshou',
        texteI18nKey: 'Member.BryanJuniorNgatshou.text',
        linkedin: 'https://www.linkedin.com/in/bryan-ngatshou/',
    },
    {
        imageName: 'Gaetan-Butault.jpg',
        posteI18nKey: 'Member.GaetanButault.role',
        name: 'Gaetan Butault',
        texteI18nKey: 'Member.GaetanButault.text',
        linkedin: 'https://www.linkedin.com/in/gaetan-butault-30b3771b5/',
    },
    // // sponsorships' team
    {
        imageName: 'Kim-Desroches.png',
        posteI18nKey: 'Member.KimDesroches.role',
        name: 'Kim Desroches',
        texteI18nKey: 'Member.KimDesroches.text',
        linkedin: 'https://www.linkedin.com/in/kim-desroches/',
    },
    {
        imageName: 'Alaa-Eddin-Addas.png',
        posteI18nKey: 'Member.AlaaEddinAddas.role',
        name: 'Alaa Eddin Addas',
        texteI18nKey: 'Member.AlaaEddinAddas.text',
        linkedin: 'https://www.linkedin.com/in/alaaeddinaddas2003/',
    },
    {
        imageName: 'Mohamed-Omar-Zedek.png',
        posteI18nKey: 'Member.MohamedOmarZedek.role',
        name: 'Omar Zedek',
        texteI18nKey: 'Member.MohamedOmarZedek.text',
        linkedin: 'https://www.linkedin.com/in/mohamed-omar-zedek/',
    },
    {
        imageName: 'No-Face.png',
        posteI18nKey: 'Member.SouhaylSejjari.role',
        name: 'Souhayl Sejjari',
        texteI18nKey: 'Member.SouhaylSejjari.text',
        linkedin: 'https://www.linkedin.com/in/souhayl-sejjari-05a09229a/',
    },
    // // education team
    {
        imageName: 'Odreanne-Breton.png',
        posteI18nKey: 'Member.OdreanneBreton.role',
        name: 'Odréanne-Breton',
        texteI18nKey: 'Member.OdreanneBreton.text',
        linkedin: 'https://www.linkedin.com/in/odreannebreton/',
    },
    {
        imageName: 'Lahbabi-Ryan.png',
        posteI18nKey: 'Member.LahbabiRyan.role',
        name: 'Lahbabi Ryan',
        texteI18nKey: 'Member.LahbabiRyan.text',
        linkedin: 'http://linkedin.com/in/ryanlahbabi/',
    },
    // // designers' team
    {
        imageName: 'Victoria-Mae-Carriere.png',
        posteI18nKey: 'Member.VictoriaMaeCarriere.role',
        name: 'Victoria-Mae Carrière',
        texteI18nKey: 'Member.VictoriaMaeCarriere.text',
        linkedin: 'https://www.linkedin.com/in/victoria-mae-carrière/',
    },
    // // events' team
    {
        imageName: 'Antoine-Leblanc.png',
        posteI18nKey: 'Member.AntoineLeblanc.role',
        name: 'Antoine Leblanc',
        texteI18nKey: 'Member.AntoineLeblanc.text',
        linkedin: 'https://www.linkedin.com/in/antoine-leblanc-a6307b235/',
    },
    // // social medias' team
    {
        imageName: 'Artour-Benevolenski.png',
        posteI18nKey: 'Member.ArtourBenevolenski.role',
        name: 'Artour Benevolenski',
        texteI18nKey: 'Member.ArtourBenevolenski.text',
        linkedin: 'https://www.linkedin.com/in/artour-benevolenski/',
    },
];

export default MEMBERS;