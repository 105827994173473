import React, { FunctionComponent } from 'react';
import '../styles/events-hero.scss';

interface EventsHeroProps {
    heroHeading: string;
    heroDescription: string;
}

const EventsHero: FunctionComponent<EventsHeroProps> = (props) => {
    return (
        <div className="hero-image">
            <h2 data-testid="heading" className="hero-heading">
                {props.heroHeading}
            </h2>
            <div data-testid="description" className="hero-description">
                {props.heroDescription}
            </div>
        </div>
    );
};

export default EventsHero;
