import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import Accordion from './accordion/accordion';
import './faq.scss';

const blockElements = {
    content: 'tabs-content',
    panel: 'tabs-panel',
    label: 'tabs-title',
};

const Faq: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Container data-testid="faq-test" className="container-faq">
            <Row className="header-faq">
                <div className="heading"> FAQ </div>
            </Row>
            {
                Array.from({length: 4}, (_, i) => (
                    <Accordion
                        key={i}
                        title={t(`Faq.element${i}.question`)}
                        content={t(`Faq.element${i}.answer`)}
                    />
                ))
            }
        </Container>
    );
};

export default Faq;