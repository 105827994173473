import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import COLLABORATORS from '../../../common/collaborators';
import PersonCard from '../person-card/person-card';
import './collaborators.scss';

const URL = 'mailto:comitepolyai@gmail.com';

const Collaborators: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <Container data-testid="collaborators-test" className="container-collaborator">
            <Row>
                <div className="heading">{t('Collaborators.ourCollaborators')}</div>
            </Row>

            <Row xs={1} md={2} lg={3} classID='collaborators-row'>
                <PersonCard
                    member={COLLABORATORS[0]}
                    backBackgroundColor="#9A808E"
                    folder="collaborators"
                />
                <PersonCard 
                    member={COLLABORATORS[1]}
                    backBackgroundColor="#9A808E"
                    folder="collaborators"
                />

            </Row>

            <Row className="center">
                <Button className="purple" onClick={() => window.open(URL, '_blank')}>
                    {t('Collaborators.becomeACollaborator')}
        </Button>
            </Row>
        </Container>

    );
};

export default Collaborators;