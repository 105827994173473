import React from 'react';
import { Container} from 'reactstrap';
import './mission-div.scss';

interface MissionProps{
    name : string;
}

const MissionDiv = ({name} : MissionProps) => {
    return (
      <Container data-testid="missiondiv-test" fluid={true} className={'container-' + name}>
        <div className="back-color"/>
        <div className="image"/>
      </Container>
    );
};

export default MissionDiv;
