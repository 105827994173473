import {PureComponent} from 'react';
import { RouteComponentProps } from 'react-router';
import {withRouter} from 'react-router-dom';

class ScrollToTop extends PureComponent<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render(): JSX.Element{
    return null;
  }
}

export default withRouter(ScrollToTop);