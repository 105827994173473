import { AnimatePresence } from 'framer-motion';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import {
    Button,
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
} from 'reactstrap';
import i18n from '../../i18n';
import AboutComponent from '../about/about';
import AccueilPage from '../accueil-page/components/accueil-page';
import EventsPage from '../events-page/components/events-page';
import DiversityInclusionPage from '../diversity-inclusion/diversity-inclusion';

import ScrollToTop from '../scrollToTop/scrollToTop';
import { ReactComponent as LogoSvg } from './../../images/logo_polyai_blanc.svg';
import LogoSvgColor from './../../images/logo_polyai_transparent.png';

import ProjectsPage from '../projects/projects';
import './navbar.scss';

const NavbarComponent: FunctionComponent = () => {
    const { t } = useTranslation();

    const toggleLanguage = () => {
        if (i18n.language === 'fr') {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('fr');
        }
    };

    const languageButtonText = () => i18n.language === 'fr' ? 'EN': 'FR';

    const size = useWindowSize();

    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    if (size !== undefined && size.width > 480) {
        return (
            <div>
                <Navbar className="custom-nav-wrapper" fixed="top">
                    <div className="custom-navbar-brand">
                        <Link to={'/'}>
                            <LogoSvg />
                        </Link>
                    </div>

                    <div className="custom-navbar-routes">
                        <NavLink
                            exact={true}
                            to={'/'}
                            className="custom-nav-link"
                            activeClassName="custom-navbar-selected"
                        >
                            {t('NavbarComponent.home')}
                        </NavLink>
                        <NavLink
                            to={'/evenements'}
                            className="custom-nav-link"
                            activeClassName="custom-navbar-selected"
                        >
                            {t('NavbarComponent.events')}
                        </NavLink>
                        <NavLink
                            to={'/projects'}
                            className="custom-nav-link"
                            activeClassName="custom-navbar-selected"
                        >
                            {t('NavbarComponent.projects')}
                        </NavLink>
                        <NavLink
                            to={'/about'}
                            className="custom-nav-link"
                            activeClassName="custom-navbar-selected"
                        >
                            {t('NavbarComponent.about')}
                        </NavLink>
                        <NavLink
                            to={'/diversity'}
                            className="custom-nav-link"
                            activeClassName="custom-navbar-selected"
                        >
                            {t('NavbarComponent.diversity')}
                        </NavLink>
                        <a
                            className="custom-nav-link"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSf6giHap-r7tGXrn7yawK_Lh3DfWfkanLhagaqrJmvWJT8nPA/viewform?usp=pp_url"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('NavbarComponent.joinUs')}
                        </a>

                        <a href="#" className="language-button" onClick={toggleLanguage}>
                            {languageButtonText()}
                        </a>

                    </div>
                    <div className="custom-navbar-newsletter">
                        <a
                             href="http://eepurl.com/hc0eUD"
                             target="_blank"
                             rel="noopener noreferrer"
                        >
                            {t('NavbarComponent.newsletter')}
                        </a>
                    </div>
                </Navbar>

                <AnimatePresence>
                    <ScrollToTop />
                    <Switch>
                        <Route exact={true} path="/" component={AccueilPage} />
                        <Route path="/evenements" component={EventsPage} />
                        <Route path="/about" component={AboutComponent} />
                        <Route path="/projects" component={ProjectsPage} />
                        <Route path="/diversity" component={DiversityInclusionPage} />
                    </Switch>
                </AnimatePresence>
            </div>
        );
    } else {
        return (
            <div>
                <Navbar className="white-background" fixed="top" light={true}>
                    <NavbarBrand href="/" className="mr-auto mobile-brand ">

                        <img
                            src={LogoSvgColor}
                            className="custom-nav-link-logo"
                            alt="PolyAI"
                        />

                    </NavbarBrand>
                    <NavbarToggler
                        onClick={toggleNavbar}
                        className="mr-2 mobile-toggler"
                    />
                    <Collapse
                        className="mobile-collapse"
                        isOpen={!collapsed}
                        navbar={true}
                    >
                        <Nav navbar={true}>
                            <NavItem>
                                <NavLink
                                    exact={true}
                                    to={'/'}
                                    className="custom-nav-link-mobile"
                                    activeClassName="custom-navbar-selected"
                                >
                                    {t('NavbarComponent.home')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/evenements'}
                                    className="custom-nav-link-mobile"
                                    activeClassName="custom-navbar-selected"
                                >
                                    {t('NavbarComponent.events')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/projects'}
                                    className="custom-nav-link-mobile"
                                    activeClassName="custom-navbar-selected"
                                >
                                    {t('NavbarComponent.projects')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/about'}
                                    className="custom-nav-link-mobile"
                                    activeClassName="custom-navbar-selected"
                                >
                                    {t('NavbarComponent.about')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/diversity'}
                                    className="custom-nav-link-mobile"
                                    activeClassName="custom-navbar-selected"
                                >
                                    {t('NavbarComponent.diversity')}
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <a
                                    className="custom-nav-link-mobile"
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSf6giHap-r7tGXrn7yawK_Lh3DfWfkanLhagaqrJmvWJT8nPA/viewform?usp=pp_url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('NavbarComponent.joinUs')}
                                </a>
                            </NavItem>
                            <NavItem>
                                <a href="#" className="custom-nav-link-mobile" onClick={toggleLanguage}>
                                    {languageButtonText()}
                                </a>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
                <AnimatePresence>
                    <ScrollToTop />
                    <Switch>
                        <Route exact={true} path="/" component={AccueilPage} />
                        <Route path="/evenements" component={EventsPage} />
                        <Route path="/about" component={AboutComponent} />
                        <Route path="/projects" component={ProjectsPage} />
                        <Route path="/diversity" component={DiversityInclusionPage} />
                    </Switch>
                </AnimatePresence>
            </div>
        );
    }
};

{
    /* <div>
<Navbar color="faded" light>
  <NavbarBrand href="/" className="mr-auto">reactstrap</NavbarBrand>
  <NavbarToggler onClick={toggleNavbar} className="mr-2" />
  <Collapse isOpen={!collapsed} navbar>
    <Nav navbar>
      <NavItem>
        <NavLink href="/components/">Components</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
      </NavItem>
    </Nav>
  </Collapse>
</Navbar>
</div> */
}

// Hook
const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
};

export default NavbarComponent;
