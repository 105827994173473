import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import './add-info.scss';

const AddInfo: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Container className="container-addinfo" fluid={true}>
      <Row xs={1}>
        <Col>
          <h1>PolyAI</h1>
        </Col>
        <Col>
          <ul className="first-list">
            <li>comitepolyai@gmail.com</li>
          </ul>
        </Col>
      </Row>

      <Row xs={1}>
        <Col>
          <h1>{t('AddInfo.followUs')}</h1>
        </Col>
        <Col>
          <ul className="second-list">
            <li id="first-li-element">
              <a href="https://www.facebook.com/branchPolyAi/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/poly-ai/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );

};

export default AddInfo;