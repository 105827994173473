import {
    faDiscord,
    faGithubSquare,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Carousel,
    CarouselControl,
    CarouselIndicators,
    CarouselItem,
} from 'reactstrap';
import MEMBERS from '../../../common/members';
import '../styles/notre-equipe.scss';

const Example = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const { t } = useTranslation();

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === MEMBERS.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? MEMBERS.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const slides = MEMBERS.map((item, key) => {
        const { t } = useTranslation();
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={key}
            >
                <div className="member-card">
                    <div className="member-position">{t(item.posteI18nKey)}</div>
                    <img className="member-portrait" src={require('../../../images/members/' + item.imageName)} />
                    <div className="member-name">{item.name}</div>
                    <a className="right-margin" href={item.linkedin} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon
                            size="2x"
                            icon={faLinkedin}
                            style={{ color: '#2867B2' }}
                        />
                    </a>
                </div>
            </CarouselItem>
        );
    });

    return (
        <div className="section-notre-equipe">
            <div className="heading">{t('Example.ourTeam')}</div>
            <Carousel
                // pause={false}
                ride="carousel"
                interval="3500"
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        </div>
    );
};

export default Example;
