import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import AddInfo from '../add-info/add-info';
import FormPropos from '../form-propos/form-propos';
import './down-page.scss';

const DownPage: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
      <Container data-testid="downpage-test" className="container-down">
        <Row xs={1} md={2}>
          <Col md={{ size: 5 }} className="col-formulaire">
            <h1>{t('DownPage.joinUs')}</h1>
            <FormPropos />
          </Col>
          <Col md={{ size: 5, offset: 1 }} className="col-addInfo">
            <AddInfo />
          </Col>
        </Row>
      </Container>
    );
};

export default DownPage;