import axios from 'axios';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import './form-propos.scss';

interface FormInputs {
    firstName: string;
    lastName: string;
    email: string;
    subject: string;
    message: string;
}

const FormPropos: FunctionComponent = () => {

    const { t } = useTranslation();
 const { register, handleSubmit, errors } = useForm<FormInputs>();
 const [ loading, setLoading ] = useState(false);
 const [ success, setSuccess ] = useState(false);
 const [ error, setError ] = useState(false);

 const onSubmit = (data: FormInputs) => {
    console.log(data);
    setLoading(true);
    axios({
        method: 'post',
        url: 'https://submit-form.com/n8sotsYXk9ephc10UAIpw',
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          subject: data.subject,
          message: data.message,
        },
      }).then((response) => {
        console.log(response);
        setLoading(false);
        setSuccess(true);
        setError(false);

        setTimeout( () => setSuccess(false), 5000);

      }).catch((requestError) => {
        console.log(requestError.response);
        setLoading(false);
        setSuccess(false);
        setError(true);

        setTimeout( () => setError(false), 5000);
      });

};

    return (
        <Form data-testid="formpropos-test" onSubmit={handleSubmit(onSubmit)}>
            <Row form={true}>
                <Col md={{ size: 5 }}>
                    <FormGroup>
                        <Label for="firstName">{t('FormPropos.name')}</Label>
                            <Input name="firstName" id="firstName" innerRef={register({ required: true })} className={errors.firstName ? 'error-required' : ''}/>

                    </FormGroup>
                </Col>
                <Col className="input-lastName" md={{ size: 5, offset: 2 }}>
                    <FormGroup>
                        <Label for="lastName">{t('FormPropos.surname')}</Label>
                            <Input name="lastName" id="lastName" innerRef={register({ required: true })} className={errors.lastName ? 'error-required' : ''}/>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
    <Label for="Email">{t('FormPropos.email')}</Label>
                <Input type="email" name="email" id="Email" className={errors.email ? 'error-required' : ''}
                        innerRef={register({
                            required: true,
                            pattern: /^[a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-z0-9]@[a-z0-9][-\.]{0,1}([a-z][-\.]{0,1})*[a-z0-9]\.[a-z0-9]{1,}([\.\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/i,
                        })} />

            </FormGroup>
            <FormGroup>
                    <Label className="a-propos-label" for="subject">{t('FormPropos.subject')}</Label>
                    <Input
                        name="subject"
                        id="subject"
                        innerRef={register({ required: true })}
                        className={errors.subject ? 'error-required' : ''}
                    />

            </FormGroup>
            <FormGroup>
                    <Label for="message" className="label-message">{t('FormPropos.message')}</Label>
                    <Input
                        type="textarea"
                        name="message"
                        id="message"
                        rows={5}
                        cols={5}
                        innerRef={register({ required: true })}
                        className={errors.message ? 'error-required' : ''} />
                    {(errors.firstName && errors.firstName.type === 'required' || errors.lastName && errors.lastName.type === 'required'
                        || errors.email && errors.email.type === 'required' || errors.subject && errors.subject.type === 'required'
                        || errors.message && errors.message.type === 'required')
                        && <p className="error-message"> {t('FormPropos.missingRequirement')}</p>}
            </FormGroup>
            <Button type="submit" className="btn-a-propos-submit" data-testid="submit-test" md={{}}>
                {loading ? (
                     <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Loader type="ThreeDots" color="#FFFFFF" height={10} width={30} />
                        </div>
                ) :
                    'Submit'
                }

            </Button>
            {success && <Alert isOpen={success}  color="success"> {t('FormPropos.messageSuccess')}</Alert>}
            {error && <Alert isOpen={error} color="danger">{t('FormPropos.success')}</Alert>}
        </Form>
    );
};
export default FormPropos;