import i18n from '../i18n';
import Member from '../interfaces/member';

const COLLABORATORS: Member[] = [
    {
        imageName: 'levistheriault.jpg',
        name: 'Lévis Thériault',
        posteI18nKey: 'Collaborator.levisTheriault.position',
        texteI18nKey: 'Collaborator.levisTheriault.text',
        linkedin: 'https://www.linkedin.com/in/l%C3%A9vis-th%C3%A9riault-7bbaab1/',
    },
    {
        imageName: 'semla.jpg',
        name: 'SEMLA',
        posteI18nKey: 'Collaborator.semla.position',
        texteI18nKey: 'Collaborator.semla.text',
        linkedin: 'https://www.linkedin.com/company/semla-research-center/',
    },

];

export default COLLABORATORS;