import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import '../styles/partenaires.scss';
import IVADO_LOGO from './../../../images/ivado_logo.png';
import BNC_LOGO from './../../../images/bnc_mf.svg';
import POLYMTL_LOGO from './../../../images/Polytechnique_logo.png';
import GURU_LOGO from './../../../images/guru_logo.svg';
import SPONSORS_AD from './../../../images/sponsors.png';
import LOGO_TEMPLATE from './../../../images/your_logo_here.png';

const SELMA_LOGO = 'https://semla.quebec/static/Images/semla.png';
const IMG_ALT_TXT = 'Could not load image';

interface AccueilPartenairesProps {
    property: string;
}

const AccueilPartenaires: FunctionComponent<AccueilPartenairesProps> = (
    props,
) => {
    const { t } = useTranslation();
    return (
        <div className="section-partenaires">
            <h3 className="heading">{t('AccueilPartenaires.heading')}</h3>
            <hr className="some-bullshit" />

            <div className="home-partners-wrapper">

                    <img
                        className="home-partners-image"
                        src={POLYMTL_LOGO}
                        alt={IMG_ALT_TXT}
                    />

                    <img
                        className="home-partners-image"
                        src={BNC_LOGO}
                        alt={IMG_ALT_TXT}
                    />

                    <img
                        className="home-partners-image"
                        src={IVADO_LOGO}
                        alt={IMG_ALT_TXT}
                    />

                    <img
                        className="home-partners-image"
                        src={GURU_LOGO}
                        alt={IMG_ALT_TXT}
                    />

                    <img 
                        className="home-partners-image"
                        src={SELMA_LOGO}
                        alt={IMG_ALT_TXT}

                    />

                    <img
                        className="home-partners-image"
                        src={LOGO_TEMPLATE}
                        alt={IMG_ALT_TXT}
                    />
            </div>

            <Container>
                <Row>
                    <Col lg={2} />
                    <Col lg={4} xs={12} className="box">
                        <div className="image-stack-container">
                            <div className="image-stack">
                                <div className="image-stack__item--top">
                                    <div className="image-top-wrapper">
                                        <img
                                            className="image-top"
                                            src={SPONSORS_AD}
                                            alt="Sponsor ad"
                                        />
                                    </div>
                                </div>
                                <div className="image-stack__item--bottom">
                                    <img
                                        data-testid="image"
                                        className="image-bottom"
                                        src={SPONSORS_AD}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} xs={12} className="box">
                        <p data-testid="description1" className="text">
                            {t('AccueilPartenaires.description1')}
                        </p>
                        <p data-testid="description2" className="text">
                            {t('AccueilPartenaires.description2')}
                        </p>
                        <a href="mailto:comitepolyai@gmail.com" target="_blank" rel="noopener noreferrer" className="link">
                            {t('AccueilPartenaires.link')}
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AccueilPartenaires;
