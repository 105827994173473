import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Jumbotron, Row } from 'reactstrap';
import DEV_PROJECTS from '../../common/dev-projects';
import RESEARCH_PROJECTS from '../../common/research-projects';
import Project from '../../interfaces/project';
import ProjectCard from './project-card/project-card';
import './projects.scss';

const URL = 'https://docs.google.com/forms/d/e/1FAIpQLSf6giHap-r7tGXrn7yawK_Lh3DfWfkanLhagaqrJmvWJT8nPA/viewform?usp=pp_url';

const ProjectsPage: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <motion.div
            className="projects-page-wrapper"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <Jumbotron className="hero" fluid={true}>
                <div className="text-hero">{t('ProjectPage.ourProjects')}</div>
            </Jumbotron>

            <Container className="our-projects">
                <Row id="recherche">
                    <h2 className="heading">{t('ProjectPage.researchProjects')}</h2>
                </Row>
                <Row xs={1} lg={2}>
                    {
                        RESEARCH_PROJECTS.map((project: Project, index: number) =>
                            <Col key={index}>
                                <ProjectCard key={index} project={project} />
                            </Col>,
                        )
                    }
                </Row>
                <Row>
                    <h5>
                        {t('ProjectPage.joinAsMentor')}
                    </h5>
                </Row>
                <Row className="center">
                <Button onClick={() => window.open(URL, '_blank')}> {t('ProjectPage.joinUs1')}</Button>
                </Row>
            </Container>

            <Container className="our-projects">
                <Row id="dev">
                <h2 className="heading">{t('ProjectPage.devProjects')}</h2>
                </Row>
                <Row>
                    {
                        DEV_PROJECTS.map((project: Project, index: number) =>
                            <Col key={index}>
                                <ProjectCard key={index} project={project} />
                            </Col>,
                        )
                    }
                </Row>
                <Row className="center">
                    <h5>
                        {t('ProjectPage.joinUs')}
                    </h5>
                </Row>
                <Row className="center">
                <Button onClick={() => window.open(URL, '_blank')}> {t('ProjectPage.joinUs1')} </Button>
                </Row>
            </Container>

        </motion.div>
    );
};

export default ProjectsPage;
