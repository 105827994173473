import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {Jumbotron} from 'reactstrap';
import './presentation.scss';

const Presentation: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="presentation-test" className="haut-de-page">
      <Jumbotron className="background-a-propos" fluid={true}>
        <div className="about-heading">{t('Presentation.about')}</div>
      </Jumbotron>
    </div>
  );
};

export default Presentation;