import i18n from '../i18n';
import METIS from '../images/project_metis.png';
import POLYCORTEX from '../images/project_polycortex.jpeg';
import Project from '../interfaces/project';

const RESEARCH_PROJECTS: Project[] = [
    {
        titleI18nKey: 'ResearchProject.exoskeleton.title',
        descriptionI18nKey: 'ResearchProject.exoskeleton.description',
        image: METIS,
        members: [
            'Youcef Anis Dilmi', 'Sanmar Simon', 'Michelle Sepkap', 'Jeremy Thai',
        ],
        mentors: [
            'Fabrice Simo Defo', 'André Diler',
        ],
    },
    {
        titleI18nKey: 'ResearchProject.sleepCycle.title',
        descriptionI18nKey: 'ResearchProject.sleepCycle.description',
        image: POLYCORTEX,
        members: [
            'Manuel Pellerin', 'Michelle Sepkap', 'Ali Ameziane Hassani',
        ],
        mentors: [
            'Fabrice Charbonneau',
        ],
    },
];

export default RESEARCH_PROJECTS;
