import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Project from '../../../interfaces/project';
import './project-card.scss';

interface ProjectCardProps {
    project: Project;
}

const ProjectCard: FunctionComponent<ProjectCardProps> = ({project}) => {
    const { t } = useTranslation();
    return (
        <Container className="card-wrapper">
            <h2>{t(project.titleI18nKey)}</h2>
            <img src={project.image} alt="Photo montrant le projet" className="card-hero"/>
            <p>{t(project.descriptionI18nKey)}</p>
            {project.mentors &&
                <div>
                    <h4>{t('ProjectCard.supervisors')}</h4>
                    <ul>
                        {project.mentors.map( (name: string, index: number) =>
                            <li key={index}>{name}</li>,
                        )}
                    </ul>
                </div>
            }
            {project.members &&
                <div>
                    <h4>{t('ProjectCard.members')}</h4>
                    <ul>
                        {project.members.map( (name: string, index: number) =>
                            <li key={index}>{name}</li>,
                        )}
                    </ul>
                </div>
            }
        </Container>

    );
};

export default ProjectCard;