import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../../images/qui-sommes-nous-background.jpeg';
import '../styles/qui-sommes-nous.scss';

interface AccueilQuiSommesNousProps {
    property: string;
}

const AccueilQuiSommesNous: FunctionComponent<AccueilQuiSommesNousProps> = (
    props,
) => {
    const { t } = useTranslation();
    return (
        <section className="section-qui-sommes-nous">
                            <img className="qui-sommes-nous-image"src={backgroundImage} alt="computer" />
            <div className="text-grid">
                <div>
                    {t('AccueilQuiSommesNous.our')}
                    <span style={{color: '#ef485d'}}>{t('AccueilQuiSommesNous.mission')}</span>
                </div>
                <div />
                <div/>
                <div>
                    {t('AccueilQuiSommesNous.statement')}
                </div>
            </div>
        </section>
    );
};

export default AccueilQuiSommesNous;
