import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/evenements.scss';
import '../styles/home-buttons.scss';
import EVENT_1_IMG from './../../../images/learn_ml.jpg';
import EVENT_2_IMG from './../../../images/code_ml_2024.png';
import EVENT_3_IMG from './../../../images/temp_event_picture.jpg';

enum EVENT_INDEXES {
    EVENT_1 = 0,
    EVENT_2,
    EVENT_3,
}
const IMG_ALT_TXT = 'error loading image';
interface AccueilEvenementsProps {
    eventTitles: string[];
    eventDescriptions: string[];
}

const AccueilEvenements: FunctionComponent<AccueilEvenementsProps> = (
    props,
) => {
    const { t } = useTranslation();
    return (
        <div className="section-events">
            <h3 className="heading">{t('AccueilPage.Evenements.title')}</h3>
            <div className="home-events-wrapper">
                <div className="home-events-small-wrapper">
                    <img
                        className="home-events-image"
                        src={EVENT_1_IMG}
                        alt={IMG_ALT_TXT}
                    />
                    <h4>{props.eventTitles[EVENT_INDEXES.EVENT_1]}</h4>
                    <br />
                    <p>{props.eventDescriptions[EVENT_INDEXES.EVENT_1]}</p>
                </div>

                <div className="home-events-small-wrapper">
                    <img
                        className="home-events-image home-events-right-bottom"
                        src={EVENT_2_IMG}
                        alt={IMG_ALT_TXT}
                    />
                    <h4>{props.eventTitles[EVENT_INDEXES.EVENT_2]}</h4>
                    <br />
                    <p>{props.eventDescriptions[EVENT_INDEXES.EVENT_2]}</p>
                </div>

                <div>
                    <img
                        className="home-events-image home-events-center"
                        src={EVENT_3_IMG}
                        alt={IMG_ALT_TXT}
                    />
                    <h4>{props.eventTitles[EVENT_INDEXES.EVENT_3]}</h4>
                    <br />
                    <p>{props.eventDescriptions[EVENT_INDEXES.EVENT_3]}</p>
                </div>
            </div>
            <div className="home-buttons-wrapper">
                <h3>
                    <Link to={'/evenements'} className="home-buttons-link" >{t('AccueilEvenements.seeEvents')}</Link>
                </h3>
            </div>
        </div>
    );
};

export default AccueilEvenements;
