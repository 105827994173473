import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col,Container,Row } from 'reactstrap';
import MissionDiv from '../mission-div/mission-div';
import './mission.scss';

const Mission: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Container data-testid="mission-test" className="container-mission">
      <Row className="header-mission">
        <div className="heading"> {t('Mission.title')} </div>
        <h2>
          {t('Mission.statement')}
        </h2>
      </Row>

      <Row className="row-communaute" xs={1} lg={2}>
        <Col xs={{ order: 2 }} lg={{ size: 5, order: 1 }} xl={{ size: 6 }}>
          <MissionDiv name="communaute" />
        </Col>
        <Col xs={{ order: 1 }} lg={{ size: 6, order: 2, offset: 0 }}>
          <div className="communaute-text">
            <div className="heading">{t('Mission.communityTitle')}</div>
            <p>
              {t('Mission.communityText')}
          </p>
          </div>
        </Col>
      </Row>

      <Row className="row-etudiants" xs={1} lg={2}>
        <Col xs={{ order: 1 }} lg={{ size: 6, order: 1 }}>
          <div className="etudiants-text">
  <div className="heading">{t('Mission.helpStudentsTitle')}</div>
            <p>
              {t('Mission.helpStudentsText')}
          </p>
          </div>
        </Col>
        <Col
          xs={{ order: 2 }}
          lg={{ size: 5, order: 2, offset: 0 }}
          xl={{ size: 6 }}
        >
          <MissionDiv name="etudiants" />
        </Col>
      </Row>

      <Row className="row-opportunite" xs={1} lg={2}>
        <Col xs={{ order: 2 }} lg={{ size: 5, order: 1 }} xl={{ size: 6 }}>
          <MissionDiv name="opportunite" />
        </Col>
        <Col xs={{ order: 1 }} lg={{ size: 6, order: 2, offset: 0 }}>
          <div className="opportunite-text">
          <div className="heading">{t('Mission.opportunitiesTitle')}</div>
            <p>
              {t('Mission.opportunitiesText')}
          </p>
          </div>
        </Col>
      </Row>

    </Container>
  );
};

export default Mission;
