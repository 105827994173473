import i18n from '../../../i18n';
import codeML from '../../../images/codeml.png';
import learnML from '../../../images/learn_ml.jpg';
import { useTranslation } from 'react-i18next';

export interface Event {
    id: number;
    date: Date;
    titleI18nKey: string;
    placeI18nKey: string;
    descriptionI18nKey: string;
    description2I18nKey: string;
    image: string;
    lien?: string;
    facebook?: string;
}

const eventsData: Event[] = [
    {
        id: 0,
        date: new Date('2024/06/08'),
        titleI18nKey: 'Event.learnml.title',
        placeI18nKey: 'Event.learnml.location',
        descriptionI18nKey: 'Event.learnml.description1',
        description2I18nKey: 'Event.learnml.description2',
        lien: '',
        image: learnML,
    },
    {
        id: 1,
        date: new Date('2024/10/05'),
        titleI18nKey: 'Event.codeml.title',
        placeI18nKey: 'Event.codeml.location',
        descriptionI18nKey: 'Event.codeml.description1',
        description2I18nKey: 'Event.codeml.description2',
        lien: 'https://codeml.ca/',
        image: codeML,
    },
    {
        id: 4,
        date: new Date('2020/09/28'),
        titleI18nKey: 'Event.introKaggle.titleI18nKey',
        placeI18nKey: 'Event.introKaggle.location',
        descriptionI18nKey: 'Event.introKaggle.description1',
        description2I18nKey: 'Event.introKaggle.description2I18nKey',
        image: '',
        lien: 'https://us02web.zoom.us/webinar/register/6315996314023/WN_tXwGoifZTAO1GQ7wRpfhHg',
        facebook: 'https://www.facebook.com/events/3261913650585985/',
    },
    {
        id: 5,
        date: new Date('2020/09/29'),
        titleI18nKey: 'Event.introNeuralNetwork.titleI18nKey',
        placeI18nKey: 'Event.introNeuralNetwork.location',
        descriptionI18nKey: 'Event.introNeuralNetwork.description1',
        description2I18nKey: 'Event.introNeuralNetwork.description2I18nKey',
        image: '',
        lien: 'https://us02web.zoom.us/webinar/register/3115996319880/WN_TSm1vnQuQ5iRaxexmgLLmw',
        facebook: 'https://www.facebook.com/events/3261913650585985/',
    },
    {
        id: 6,
        date: new Date('2020/09/30'),
        titleI18nKey: 'Event.internshipFair.titleI18nKey',
        placeI18nKey: 'Event.internshipFair.location',
        descriptionI18nKey: 'Event.internshipFair.description1',
        description2I18nKey: 'Event.internshipFair.description2I18nKey',
        image: '',
        lien: 'https://ivado.ca/evenement/foire-aux-stages-et-emplois-ivado-en-intelligence-numerique/',
        facebook: 'https://www.facebook.com/events/3261913650585985/',
    },
    {
        id: 7,
        date: new Date('2020/10/1'),
        titleI18nKey: 'Event.ivadoConference.titleI18nKey',
        placeI18nKey: 'Event.ivadoConference.location',
        descriptionI18nKey: 'Event.ivadoConference.description1',
        description2I18nKey: 'Event.ivadoConference.description2I18nKey',
        image: '',
        lien: 'https://www.eventbrite.ca/e/billets-octobre-numerique-ivado-digital-october-ivado-110803975674',
        facebook: 'https://www.facebook.com/events/3261913650585985/',
    },
    {
        id: 8,
        date: new Date('2020/10/1'),
        titleI18nKey: 'Event.ivadoNetworking.titleI18nKey',
        placeI18nKey: 'Event.ivadoNetworking.location',
        descriptionI18nKey: 'Event.ivadoNetworking.description1',
        description2I18nKey: 'Event.ivadoNetworking.description2I18nKey',
        image: '',
        lien: 'https://us02web.zoom.us/webinar/register/2515996343195/WN_qEd14UuATvyqg5zrPzPI9g',
        facebook: 'https://www.facebook.com/events/3261913650585985/',
    },
    {
        id: 9,
        date: new Date('2020/10/2'),
        titleI18nKey: 'Event.researchDay.titleI18nKey',
        placeI18nKey: 'Event.researchDay.location',
        descriptionI18nKey: 'Event.researchDay.description1',
        description2I18nKey: 'Event.researchDay.description2I18nKey',
        image: '',
        lien: 'https://us02web.zoom.us/webinar/register/4215996347259/WN_3BAgt_15SzqPGO11p4E_Cw',
        facebook: 'https://www.facebook.com/events/3261913650585985/',
    },
    {
        id: 2,
        date: new Date('2020/10/8'),
        titleI18nKey: 'Event.dataScienceInterview.titleI18nKey',
        placeI18nKey: 'Event.dataScienceInterview.location',
        descriptionI18nKey: 'Event.dataScienceInterview.description1',
        description2I18nKey: 'Event.dataScienceInterview.description2I18nKey',
        image: '',
    },
    {
        id: 3,
        date: new Date('2020/09/03'),
        titleI18nKey: 'Event.dataScienceInterview.titleI18nKey',
        placeI18nKey: 'Event.dataScienceInterview.location',
        descriptionI18nKey: 'Event.dataScienceInterview.description1',
        description2I18nKey: 'Event.dataScienceInterview.description2I18nKey',
        image: '',
        lien: 'https://www.facebook.com/events/3566711263359664/',
    },
];

export const dateSort = (a: Event, b: Event): number => {
    return a.date.getTime() - b.date.getTime();
};

const ONE_DAY_MS = 86400000;

export const dateHasPassed = (date: Date): boolean => {
    const currentDate: Date = new Date();
    if (date.getTime() > currentDate.getTime() - ONE_DAY_MS) {
        return false;
    } else {
        return true;
    }
};

export const isToday = (date: Date): boolean => {
    const today: Date = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const generator = (events: Event[], month: number): (() => Event[]) => {
    return (): Event[] => {
        const eventsByMonth: Event[] = events.filter((event) => {
            return (
                event.date.getMonth() === month && !dateHasPassed(event.date)
            );
        });
        eventsByMonth.sort(dateSort);
        return eventsByMonth;
    };
};
const JUNE = 5;
const SEPTEMBER = 8;
const OCTOBER = 9;
const NOVEMBER = 10;
const DECEMBER = 11;

export interface MonthBlock {
    monthI18nKey: string;
    events: () => Event[];
}

export const calendarEvents = ()=>{
    const { t } = useTranslation();

    return(
    [
    {
        monthI18nKey: t('Month.june'),
        events: (): Event[] => {
            const septemberEvents = generator(eventsData, JUNE);
            return septemberEvents();
        },
    },
    {
        monthI18nKey: t('Month.september'),
        events: (): Event[] => {
            const septemberEvents = generator(eventsData, SEPTEMBER);
            return septemberEvents();
        },
    },

    {
        monthI18nKey: t('Month.october'),
        events: (): Event[] => {
            const octoberEvents = generator(eventsData, OCTOBER);
            return octoberEvents();
        },
    },

    {
        monthI18nKey: t('Month.november'),
        events: (): Event[] => {
            const novemberEvents = generator(eventsData, NOVEMBER);
            return novemberEvents();
        },
    },

    {
        monthI18nKey: t('Month.december'),
        events: (): Event[] => {
            const decemberEvents = generator(eventsData, DECEMBER);
            return decemberEvents();
        },
    },
]);}

export const formatDate = (date: Date, lg:string): string => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return new Intl.DateTimeFormat(lg, options).format(date);
};

export default eventsData;
