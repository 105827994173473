import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import heroAccueil from '../../../images/hero-accueil.jpeg';
import logoPolyAI from '../../../images/logo_polyai_transparent.png';
import '../styles/hero.scss';

interface AccueilHeroProps {
    property: string;
}

const AccueilHero: FunctionComponent<AccueilHeroProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="container-hero">
            <img src={heroAccueil} className="hero-image-accueil" alt="Data has a better idea" />
            <div className="hero-text">
                <div className="headline">
                    { t('AccueilHero.headline0')}
                    <span><img className="logo-polyai" src={logoPolyAI} alt=""/></span>
                </div>
                <div className="headline">
                    { t('AccueilHero.headline1')}
                </div>
            </div>
        </div>
    );
};

export default AccueilHero;
