import React, { FunctionComponent } from 'react';
import { BrowserRouter} from 'react-router-dom';
import FooterComponent from '../footer/footer';
import NavbarComponent from '../navbar/navbar';

const App: FunctionComponent = () => {
  return (
      <BrowserRouter>
        <NavbarComponent/>
        <FooterComponent/>
      </BrowserRouter>
  );
};

export default App;
