import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/accueil-page.scss';
import AccueilEvenements from './evenements';
import AccueilHero from './hero';
import AccueilNotreEquipe from './notre-equipe';
import AccueilPartenaires from './partenaires';
import AccueilQuiSommesNous from './qui-sommes-nous';
import Separator from './separator';

const AccueilPage: FunctionComponent = () => {

    const { t } = useTranslation();
    const EVENT_TITLES = [t('AccueilPage.eventTitle0'), t('AccueilPage.eventTitle1'), t('AccueilPage.eventTitle2')];
    const EVENT_DESCRIPTIONS = [t('AccueilPage.eventDescription0'), t('AccueilPage.eventDescription1'), t('AccueilPage.eventDescription2')];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <AccueilHero property="" />
            <AccueilQuiSommesNous property="" />
            <AccueilEvenements
                eventTitles={EVENT_TITLES}
                eventDescriptions={EVENT_DESCRIPTIONS}
            />
            <AccueilNotreEquipe />
            <AccueilPartenaires property="" />
        </motion.div>
    );
};

export default AccueilPage;
