import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Event } from '../resources/events-data';
import '../styles/events-featured.scss';
import '../styles/events-page.scss';

interface EventsFeaturedProps {
    featuredEvent: Event;
    date: string;
}

const EventsFeatured: FunctionComponent<EventsFeaturedProps> = ({featuredEvent, date}) => {
    const { t } = useTranslation();

    return (
        <section className="section-featured">
            <Container>
                <h2 data-testid="heading" className="section-featured-heading">
                    {t(featuredEvent.titleI18nKey)}
                </h2>
                <p data-testid="date" className="section-featured-date">
                    {date}
                </p>

                <Row>
                    <Col lg={6} md={12} className="section-featured-box">
                        <div className="image-stack-container">
                            <div className="image-stack-events">
                                <div className="image-stack__item--top">
                                    <div className="image-top-wrapper">
                                        <img
                                            className="image-top"
                                            src={featuredEvent.image}
                                            alt="Team Work"
                                        />
                                    </div>
                                </div>
                                <div className="image-stack__item--bottom">
                                    <img
                                        data-testid="image"
                                        className="image-bottom"
                                        src={featuredEvent.image}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} className="section-featured-box">
                        <p
                            data-testid="description1"
                            className="section-featured-text"
                        >
                            {t(featuredEvent.descriptionI18nKey)}
                        </p>
                        <p
                            data-testid="description2"
                            className="section-featured-text"
                        >
                            {t(featuredEvent.description2I18nKey)}
                        </p>
                        
                        { featuredEvent.lien && (<a href="http://www.codeml.ca" className="link">
                            {t('EventsFeatured.seeCodeML')}
                        </a>)}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default EventsFeatured;
