import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import MEMBERS from '../../../common/members';
import Member from '../../../interfaces/member';
import PersonCard from '../person-card/person-card';
import './team.scss';

const URL = 'https://docs.google.com/forms/d/e/1FAIpQLSf6giHap-r7tGXrn7yawK_Lh3DfWfkanLhagaqrJmvWJT8nPA/viewform?usp=pp_url';

const Team: FunctionComponent = () => {
  const { t } = useTranslation();
  
  return (
    <Container data-testid="team-test" className="container-team">
      <Row className="header-team">
        <div className="heading">{t('Team.ourTeam')}</div>
      </Row>

      <Row xs={1} md={2} lg={3}>
        {MEMBERS.map((member: Member, index: number) =>
          <Col key={index}>
            <PersonCard
              member={member}
              backBackgroundColor="#EF485D"
              folder="members"
            />
          </Col>,
        )}

      </Row>

      <Row id="rejoindre-lequipe" className="center">
        <Button className="pink" onClick={() => window.open(URL, '_blank')}>
          {t('Team.joinUs')}
        </Button>
      </Row>
    </Container>
  );
};

export default Team;