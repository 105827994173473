import 'aos/dist/aos.css';
import AOS from 'aos';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Button, Collapse } from 'reactstrap';
import {
    Event,
    formatDate,
    isToday,
    MonthBlock,
} from '../resources/events-data';
import '../styles/events-calendar.scss';
import { useTranslation } from 'react-i18next';

interface EventsCalendarProps extends WithTranslation {
    calendarHeading: string;
    color: string;
    events: MonthBlock[];
}

interface EventsCalendarState {
    collapse: number | null;
}

class EventsCalendar extends React.Component<EventsCalendarProps, EventsCalendarState> {
    constructor(props: EventsCalendarProps) {
        super(props);
        AOS.init();
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: null };

        this.renderElement = this.renderElement.bind(this);
    }

    toggle(eventID: number): void {
        this.setState({
            collapse: this.state.collapse === eventID? null : eventID,
        });
    }

    renderElement(data: Event, key: number) {
        const { t} = this.props;
        return (
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{ background: `${this.props.color}`, color: '#fff' }}
                key={key}
                contentStyle={{ borderTop: `3px solid ${this.props.color}` }}
            >
                <h3 data-testid="title" className="vertical-timeline-element-title">
                    {t(data.titleI18nKey)}
                </h3>
                <h4 data-testid="place" className="vertical-timeline-element-subtitle">
                    {t(data.placeI18nKey)}
                </h4>
                <h4 data-testid="date" className="vertical-timeline-element-subtitle">
                    {isToday(data.date) ? t('EventsCalendar.today'): formatDate(data.date,t("EventsPage.DateTimeFormat.lg"))}
                </h4>
                <div onClick={() => this.toggle(data.id)} className="details">
                    {t("seeMore")}&nbsp;
                    <FontAwesomeIcon
                        icon={this.state.collapse === data.id ? faMinus : faPlus}
                        className="polyai-red"
                    />
                </div>
                <Collapse className="collapse-wrapper" isOpen={this.state.collapse === data.id}>
                    <p data-testid="description" >{t(data.descriptionI18nKey)}</p>
                    {data.lien &&
                        <Button className="btn-sinscrire" onClick={() => window.open(data.lien, '_blank')}>
                            {t('EventsCalendar.signup')}
                        </Button>
                    }
                    {data.facebook &&
                        <Button className="btn-facebook" onClick={() => window.open(data.facebook, '_blank')}>
                            {t('EventsCalendar.facebook')}
                        </Button>
                    }
                </Collapse>
            </VerticalTimelineElement>
        );
    }

    render() {
        return (
            <section className="section-calendar">
                <h2 data-testid="heading"
                className="heading-calendar">
                    {this.props.calendarHeading}
                </h2>
                <VerticalTimeline>
                    {this.props.events.map((data: MonthBlock, key: number) => {
                        return (
                            <div key={key}>
                                {data.events().length > 0 && (
                                    <div
                                        className="circle"
                                        style={
                                            key === 0
                                                ? { marginTop: '-2rem' }
                                                : {}
                                        }
                                        data-aos="fade-right"
                                    >
                                        {data.monthI18nKey}
                                    </div>
                                )}
                                <div>
                                    {data.events().map(this.renderElement)}
                                </div>
                            </div>
                        );
                    })}
                </VerticalTimeline>
            </section>
        );
    }
}

export default withTranslation()(EventsCalendar);
