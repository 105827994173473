import {motion} from 'framer-motion';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import eventsData, { calendarEvents } from './../resources/events-data';
import EventsCalendar from './events-calendar';
import EventsFeatured from './events-featured';
import EventsHero from './events-hero';

const EventsPage: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <motion.div
        initial={{opacity: 0}}
        exit={{opacity: 0}}
        animate={{opacity: 1}}
        className="events-page"
        >
            <EventsHero
                heroHeading={t('EventsPage.heroHeading')}
                heroDescription={t('EventsPage.heroDescription')}
            />
            <EventsFeatured
                date={t('EventsPage.date1')}
                featuredEvent={eventsData[0]}
            />
            <EventsFeatured
                date={t('EventsPage.date2')}
                featuredEvent={eventsData[1]}
            />
            <EventsCalendar
                calendarHeading={t('EventsPage.calendarHeading')}
                color="#ef4852"
                events= {calendarEvents()}
            />
        </motion.div>
    );
};

export default EventsPage;
