import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Member from '../../../interfaces/member';
import './person-card.scss';

interface PersonCardProps{
    member: Member;
    backBackgroundColor: string;
    folder: string;
}

const PersonCard: FunctionComponent<PersonCardProps> = ({member, backBackgroundColor, folder}) => {
  const { t } = useTranslation();
  return (
    <Container data-testid="teamcard-test" fluid={true}>
      <div className="div-card">
        <div className="card-presentation">
          <div className="image-wrapper">
          <img 
              alt={t(member.posteI18nKey)}
              src={require('../../../images/' + folder + '/' + member.imageName)}
            />
          </div>
          <h3>{member.name}</h3>
          <h4>{t(member.posteI18nKey)}</h4>
        </div>
        <div className="card-text" style={ {background: backBackgroundColor} }>
          <h3>{member.name}</h3>
          <h4>{t(member.posteI18nKey)}</h4>
          <p>{t(member.texteI18nKey)}</p>
          {member.linkedin &&
            <ul>
              <li>
                <a href={member.linkedin}>
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
            </ul>
          }
        </div>
      </div>
    </Container>
  );
};

export default PersonCard;